exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-advisers-contact-page-js": () => import("./../../../src/templates/advisersContactPage.js" /* webpackChunkName: "component---src-templates-advisers-contact-page-js" */),
  "component---src-templates-ambassadors-page-js": () => import("./../../../src/templates/ambassadorsPage.js" /* webpackChunkName: "component---src-templates-ambassadors-page-js" */),
  "component---src-templates-choice-menu-page-js": () => import("./../../../src/templates/choiceMenuPage.js" /* webpackChunkName: "component---src-templates-choice-menu-page-js" */),
  "component---src-templates-contest-dates-page-js": () => import("./../../../src/templates/contestDatesPage.js" /* webpackChunkName: "component---src-templates-contest-dates-page-js" */),
  "component---src-templates-contests-and-projects-page-js": () => import("./../../../src/templates/contestsAndProjectsPage.js" /* webpackChunkName: "component---src-templates-contests-and-projects-page-js" */),
  "component---src-templates-download-page-js": () => import("./../../../src/templates/downloadPage.js" /* webpackChunkName: "component---src-templates-download-page-js" */),
  "component---src-templates-education-page-js": () => import("./../../../src/templates/educationPage.js" /* webpackChunkName: "component---src-templates-education-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/homePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-how-to-apply-page-js": () => import("./../../../src/templates/howToApplyPage.js" /* webpackChunkName: "component---src-templates-how-to-apply-page-js" */),
  "component---src-templates-information-page-js": () => import("./../../../src/templates/informationPage.js" /* webpackChunkName: "component---src-templates-information-page-js" */),
  "component---src-templates-map-page-js": () => import("./../../../src/templates/mapPage.js" /* webpackChunkName: "component---src-templates-map-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/newsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-offer-page-js": () => import("./../../../src/templates/offerPage.js" /* webpackChunkName: "component---src-templates-offer-page-js" */),
  "component---src-templates-operators-page-js": () => import("./../../../src/templates/operatorsPage.js" /* webpackChunkName: "component---src-templates-operators-page-js" */),
  "component---src-templates-single-contest-and-project-page-js": () => import("./../../../src/templates/singleContestAndProjectPage.js" /* webpackChunkName: "component---src-templates-single-contest-and-project-page-js" */),
  "component---src-templates-single-education-js": () => import("./../../../src/templates/singleEducation.js" /* webpackChunkName: "component---src-templates-single-education-js" */),
  "component---src-templates-single-news-js": () => import("./../../../src/templates/singleNews.js" /* webpackChunkName: "component---src-templates-single-news-js" */),
  "component---src-templates-single-vote-page-js": () => import("./../../../src/templates/singleVotePage.js" /* webpackChunkName: "component---src-templates-single-vote-page-js" */),
  "component---src-templates-text-page-js": () => import("./../../../src/templates/textPage.js" /* webpackChunkName: "component---src-templates-text-page-js" */),
  "component---src-templates-votes-page-js": () => import("./../../../src/templates/votesPage.js" /* webpackChunkName: "component---src-templates-votes-page-js" */),
  "component---src-templates-votes-results-page-js": () => import("./../../../src/templates/votesResultsPage.js" /* webpackChunkName: "component---src-templates-votes-results-page-js" */)
}

